window.global ||= window;
import Hydra from "hydra-synth";

if (document.getElementById("index-hydra")) {
  const isReduced = !!(
    window.matchMedia("(prefers-reduced-motion: reduce)") === true
    || window.matchMedia("(prefers-reduced-motion: reduce)").matches === true
  );

  const toggle = document.getElementById("toggle-hydra");

  // create a new hydra-synth instance
  window.hydra = new Hydra({
    canvas: document.getElementById("index-hydra"),
    detectAudio: false,
    makeGlobal: false,
  }).synth;

  //Glitch River
  //Flor de Fuego
  //https://flordefuego.github.io/
  window.play = function() {
    window.hydra
      .voronoi(8, 1)
      .mult(
        window.hydra
          .osc(10, 0.1, () => Math.sin(window.hydra.time) * 3)
          .saturate(3)
          .kaleid(200)
      )
      .modulate(window.hydra.o0, 0.5)
      .add(window.hydra.o0, 0.8)
      .scrollY(-0.01)
      .scale(0.99)
      .modulate(window.hydra.voronoi(8, 1), 0.008)
      .luma(0.3)
      .out();

    const content = toggle.innerText;

    toggle.innerText = toggle.dataset.content;
    toggle.dataset.content = content;
    toggle.dataset.state = "play";
  }

  window.stop = function() {
    window.hydra.solid(0, 0, 0).out();

    const content = toggle.innerText;

    toggle.innerText = toggle.dataset.content;
    toggle.dataset.content = content;
    toggle.dataset.state = "pause";
  }

  isReduced ? window.stop() : window.play();

  toggle.addEventListener("click", function(event) {
    event.preventDefault();

    toggle.dataset.state == "play" ? window.stop() : window.play();
  });
}
